<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <DistibutorMenu></DistibutorMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row class="rowBg">
          <v-col cols="12">
            <h5>
              <v-icon size="25" color="#2776ED" class="mr-4"
                >mdi-information</v-icon
              >
              My Wallet
            </h5>
          </v-col>
        </v-row>

        <v-row wrap class="mt-10">
          <v-col cols="12">
            <h5>Account Overview</h5>
            <v-layout class="rowBg">
              <v-col cols="12">
                <!---- Wallet Login---->
                <v-dialog v-model="dialogWalletLogin" persistent width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      color="#72BD61"
                      v-bind="attrs"
                      v-on="on"
                      v-if="!walletLocked"
                    >
                      Show my Balance
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 white">
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        color="black"
                        @click="dialogWalletLogin = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-4">
                      <v-row class="mt-3">
                        <v-col cols="12" align="center">
                          <p>
                            <v-btn
                              fab
                              dark
                              style="background: grey"
                              large
                              width="70"
                              height="70"
                            >
                              <v-icon dark>
                                mdi-cash-multiple
                              </v-icon>
                            </v-btn>
                          </p>
                          <p>
                            Please enter your wallet pin
                          </p>
                          <p>
                            <v-progress-linear
                              color="#7CBF46"
                              v-show="loader"
                              :indeterminate="true"
                            ></v-progress-linear>
                          </p>
                          <h5 class="text-danger" align="center">
                            {{ errorMessage }}
                          </h5>
                          <p>
                            <v-col cols="8">
                              <v-text-field
                                name="password"
                                label="Enter Pin"
                                solo
                                dense
                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.counter]"
                                :type="show3 ? 'text' : 'password'"
                                id="password"
                                counter
                                maxlength="4"
                                v-model="walletLogin.pin"
                                @click:append="show3 = !show3"
                                color="#7CBF46"
                                hint="Enter 4 digit wallet ID (E.g 0000)"
                              ></v-text-field>
                            </v-col>
                          </p>
                          <p>
                            <v-btn
                              outlined
                              class="buttonStyle"
                              @click="loginWallet"
                            >
                              Submit
                            </v-btn>
                          </p>
                          <p>
                            <v-btn
                              text
                              dark
                              color="#7CAFFC"
                              @click="requestLink"
                            >
                              <v-icon size="25" color="#BD2121" class="mr-3"
                                >mdi-emoticon-sad</v-icon
                              >
                              Forgot Wallet Pin?
                            </v-btn>
                          </p>

                          <p>
                            <span>Don't have a pin. </span>

                            <v-btn
                              text
                              dark
                              color="#7CAFFC"
                              @click="requestLink"
                            >
                              Create a new wallet pin.
                            </v-btn>

                            <!----Dialog Email Confirmation---->
                            <v-dialog
                              v-model="dialogEmailConfirmation"
                              persistent
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h6 white">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    icon
                                    color="black"
                                    @click="dialogEmailConfirmation = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                  <v-row class="mt-3">
                                    <v-col cols="12" align="center">
                                      <p>
                                        <v-img
                                          alt="Email Confirmation"
                                          class="shrink mr-2"
                                          contain
                                          :src="'img/emailConfirmation.png'"
                                          transition="scale-transition"
                                          width="150"
                                        />
                                      </p>
                                      <p>
                                        Please kindly check your email for your
                                        wallet pin activation link and follow
                                        the process to setup your Wallet
                                        passcode
                                      </p>
                                      <p>
                                        <v-btn
                                          outlined
                                          class="buttonStyle"
                                          @click="VerifyEmail"
                                        >
                                          OK
                                        </v-btn>
                                      </p>
                                      <p>
                                        <span>Don't get link </span>
                                        <span>Resend Link </span>
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <!-- Dailog to verify Email--->
                            <v-dialog
                              v-model="dialogVerifyEmail"
                              persistent
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h6 white">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    icon
                                    color="black"
                                    @click="dialogVerifyEmail = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                  <v-row class="mt-3">
                                    <v-col cols="12" align="center">
                                      <p>
                                        <v-img
                                          alt="Email Confirmation"
                                          class="shrink mr-2"
                                          contain
                                          :src="'img/emailConfirmation.png'"
                                          transition="scale-transition"
                                          width="150"
                                        />
                                      </p>
                                      <p>
                                        Please input the verification code sent
                                        to your email to continue
                                      </p>
                                      <p>
                                        <v-col cols="10">
                                          <v-text-field
                                            v-model="verificationCode.code"
                                            label="Verification Code"
                                            solo
                                            dense
                                          ></v-text-field>
                                        </v-col>
                                      </p>
                                      <p>
                                        <v-btn
                                          outlined
                                          class="buttonStyle"
                                          @click="verifyCode"
                                        >
                                          Verify
                                        </v-btn>
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <!-- Dailog to Change Wallet Pin--->
                            <v-dialog
                              v-model="dialogChangePin"
                              persistent
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h6 white">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    icon
                                    color="black"
                                    @click="dialogChangePin = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                  <v-row class="mt-3">
                                    <v-col cols="12" align="center">
                                      <p>
                                        <v-btn
                                          fab
                                          dark
                                          style="background: grey"
                                          large
                                          width="70"
                                          height="70"
                                        >
                                          <v-icon dark>
                                            mdi-cash-multiple
                                          </v-icon>
                                        </v-btn>
                                      </p>
                                      <p>
                                        <v-progress-linear
                                          color="#7CBF46"
                                          v-show="loader"
                                          :indeterminate="true"
                                        ></v-progress-linear>
                                      </p>
                                      <h5 class="text-danger" align="center">
                                        {{ errorMessage }}
                                      </h5>
                                      <p>
                                        Kindly input 4 digits pin
                                      </p>
                                      <p>
                                        <v-col cols="8">
                                          <v-text-field
                                            v-model="walletLogin.pin"
                                            label="Enter Wallet Pin"
                                            solo
                                            dense
                                            :rules="[
                                              rules.required,
                                              rules.counter,
                                            ]"
                                            counter
                                            maxlength="4"
                                            hint="Enter 4 digit wallet ID (E.g 0000)"
                                          ></v-text-field>
                                        </v-col>
                                      </p>
                                      <p>
                                        <v-btn
                                          outlined
                                          class="buttonStyle"
                                          @click="changePin"
                                        >
                                          Save
                                        </v-btn>
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <!-- Dailog to verify pin--->
                            <v-dialog
                              v-model="dialogChangePinSuccess"
                              persistent
                              width="500"
                            >
                              <v-card>
                                <v-card-title class="text-h6 white">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    icon
                                    color="black"
                                    @click="dialogChangePinSuccess = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-title>
                                <v-card-text class="mt-4">
                                  <v-row class="mt-3">
                                    <v-col cols="12" align="center">
                                      <p>
                                        <v-img
                                          alt="Email Confirmation"
                                          class="shrink mr-2"
                                          contain
                                          :src="'img/pinConfirmed.png'"
                                          transition="scale-transition"
                                          width="150"
                                        />
                                      </p>
                                      <p>
                                        Congratulations your wallet pin have
                                        been successfully setup and activated
                                      </p>

                                      <p>
                                        <v-btn
                                          outlined
                                          class="buttonStyle"
                                          @click="confirmWalletPin"
                                        >
                                          Continue
                                        </v-btn>
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-layout wrap v-if="walletLocked">
                  <v-col cols="8">
                    <p class="titleStyleColor">
                      {{ systemSetting.systemCurrency }}
                      {{ userDetail.Wallet.balance }}
                    </p>

                    <h6>Current Balance</h6>
                  </v-col>
                  <v-col cols="3">
                    <!----Dialog Create Wallet Pin---->
                    <v-dialog v-model="dialogFundWallet" persistent width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="my-5"
                          fab
                          dark
                          style="background: linear-gradient(180deg, #72BD61 0%, #000000 100%);"
                          large
                          width="90"
                          height="90"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h6 white">
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="black"
                            @click="dialogFundWallet = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text class="mt-4">
                          <v-row>
                            <v-col cols="12" align="center">
                              <p>
                                <v-img
                                  alt="Email Check"
                                  class="shrink mr-2"
                                  contain
                                  :src="'img/fundWallet.png'"
                                  transition="scale-transition"
                                  width="100"
                                />
                              </p>
                              <p class="titleStyleColorSm">
                                Fund Wallet
                              </p>

                              <p>
                                Enter Amount you wish to fund
                              </p>
                              <p>
                                <v-col cols="8">
                                  <v-text-field
                                    label="Amount"
                                    solo
                                    dense
                                  ></v-text-field>
                                </v-col>
                              </p>
                              <p>
                                <v-btn
                                  outlined
                                  class="buttonStyle"
                                  @click="addFund"
                                >
                                  Fund Wallet
                                </v-btn>
                              </p>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <!----Dialog Payment Method---->
                    <v-dialog
                      v-model="dialogPaymentMethods"
                      persistent
                      width="600"
                    >
                      <v-card>
                        <v-card-title class="text-h6 white">
                          <p class="mt-3">Payment Method</p>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="black"
                            @click="dialogPaymentMethods = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text class="mt-4">
                          <v-row wrap>
                            <v-col cols="12" md="4">
                              <v-img
                                alt="paystack logo"
                                contain
                                :src="'img/paystack.png'"
                                transition="scale-transition"
                                width="100%"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-img
                                alt="PayPal logo"
                                contain
                                :src="'img/PayPal.png'"
                                transition="scale-transition"
                                width="100%"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-img
                                alt="Stripe logo"
                                contain
                                :src="'img/stripe.png'"
                                transition="scale-transition"
                                width="100%"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-divider class="mt-10"></v-divider>

        <v-row wrap class="mt-2">
          <v-col cols="12" v-if="transactionHistories >= 1">
            <v-layout>
              <v-col cols="6">
                <h6>Transaction History</h6>
              </v-col>
              <v-col cols="6" align="right">
                View All
              </v-col>
            </v-layout>

            <v-layout
              class="rowBg pa-3 mt-2"
              v-for="(transaction, index) in transactionHistories"
              :key="index"
            >
              <v-col cols="2" class="hidden-sm-and-down">
                <v-icon size="30" color="#72BD61">mdi-history</v-icon>
              </v-col>
              <v-col cols="8" md="6" align="left">
                <h6>{{ transaction.transactionDesc }}</h6>
                <p>{{ transaction.createAt }}</p>
              </v-col>
              <v-col cols="4" align="right">
                <p class="titleStyleColorSm">
                  {{ systemSetting.systemCurrency }}{{ transaction.amount }}
                </p>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import DistibutorMenu from "../others/DistributorMenu.vue";

export default {
  name: "Wallet",

  components: {
    DistibutorMenu,
  },

  data: () => ({
    loader: false,
    dialogFundWallet: false,
    dialogWalletLogin: false,
    dialogEmailConfirmation: false,
    dialogVerifyEmail: false,
    dialogChangePin: false,
    dialogChangePinSuccess: false,
    dialogPaymentMethods: false,
    walletLocked: false,
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/distributor_dashboard/en/home",
      },
      {
        text: "Wallet",
        disabled: true,
        href: "/",
      },
    ],
    show3: false,
    errorMessage: "",
    walletLogin: {
      pin: "",
    },
    walletId: "",
    verificationCode: {
      code: "",
    },
    userDetail: {},
    transactionHistories: [],
    systemSetting: {
      systemCurrency: "₦",
    },
    userId: "",
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 4 || "Max 4 digits",
    },
  }),
  mounted() {
    this.setAuthenticatedUser();
    this.fetchWalletHistory();
  },
  methods: {
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          this.userDetail = response.data.data;
          this.walletId = response.data.data.Wallet.walletId;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    fetchWalletHistory() {
      this.$http
        .get(`${process.env.VUE_APP_URL}wallet/` + this.userId)
        .then((response) => {
          this.transactionHistories = response.data.data.WalletHistories;
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    requestLink() {
      this.$http
        .post(`${process.env.VUE_APP_URL}wallet/send-email/` + this.userId)
        .then((response) => {
          if (response.data.success == true) {
            this.dialogWalletLogin = false;
            this.dialogEmailConfirmation = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    VerifyEmail() {
      this.dialogEmailConfirmation = false;
      this.dialogVerifyEmail = true;
    },
    verifyCode() {
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/confirm-email-pin/` +
            this.userId,
          this.verificationCode
        )
        .then((response) => {
          if (response.data.success == true) {
            this.dialogVerifyEmail = false;
            this.dialogChangePin = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    changePin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/set-pin/` + this.userId,
          this.walletLogin
        )
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            this.dialogChangePin = false;
            this.dialogChangePinSuccess = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    addFund() {
      this.dialogFundWallet = false;
      this.dialogPaymentMethods = true;
    },
    loginWallet() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/login/` + this.userId,
          this.walletLogin
        )
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            this.dialogWalletLogin = false;
            this.walletLocked = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    confirmWalletPin() {
      this.dialogChangePinSuccess = false;
      this.walletLocked = true;
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleStyleColorSm {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
